<template>
  <div class="caseDetail">
    <PageTitle ttl-en="CASE" ttl-jp="_想定課題" />
    <div class="inner">
      <p class="caseDetail__mv js-scroll">
        <img src="/genbashiko/img/case/case04/mv.jpg" alt="" />
      </p>
      <section class="caseDetail__outline">
        <h1 class="js-scroll">
          強すぎる照明や反射によって
          <br />作業員の眼の疲れが生じやすい
        </h1>
        <p class="caseDetail__outline__txt js-scroll">
          エンジンなどの検査ではワークに生じる影を解消するために、対策として蛍光灯やLEDを増やす現場が少なくありません。ところがその対策が、作業員にとっては眩しさの原因となってしまいがちです。さらに通常の蛍光灯やLEDなどの円柱型の照明では、反射する際に光が線状に集約されて目に入ってきます。円柱形照明はもともと直接見ても光が強く見えますが、さらに眩しさが増幅して作業員の眼の疲労につながります。
        </p>
        <div class="caseDetail__outline__cate js-scroll">
          <p>担当領域:</p>
          <ul>
            <li>
              <router-link
                class="category"
                :to="{ name: 'Case', query: { category: '現状の課題を確認したい' }}"
              >
                現状の課題を確認したい
              </router-link>
            </li>
            <li>
              <router-link
                class="category"
                :to="{ name: 'Case', query: { category: 'エンジン・パワートレイン工程' }}"
              >
                エンジン・パワートレイン工程
              </router-link>
            </li>
            <li>
              <router-link class="category" to="/case?category=現場調査">
                現場調査
              </router-link>
            </li>
            <li>
              <router-link class="category" to="/case?category=施策策定">
                施策策定
              </router-link>
            </li>
            <li>
              <router-link
                class="category"
                to="/case?category=コンサルティング"
              >
                コンサルティング
              </router-link>
            </li>
            <li>
              <router-link class="category" to="/case?category=製品開発">
                製品開発
              </router-link>
            </li>
            <li>
              <router-link class="category" to="/case?category=納品後サポート">
                納品後サポート
              </router-link>
            </li>
          </ul>
        </div>
      </section>
      <section class="caseDetail__env">
        <h3 class="caseDetail__env__ttl js-scroll">
          想定される環境
        </h3>
        <ul class="js-scroll">
          <li>シリンダーヘッドやコンロッドなどの検査台</li>
          <li>エンジンブロックなどで使用される対象ワークの可動検査台</li>
          <li>蛍光灯のライン照明が上部に設置してある。</li>
        </ul>
      </section>
      <section class="caseDetail__ex">
        <h3 class="caseDetail__ex__ttl js-scroll">
          想定課題と具体的な改善施策例
        </h3>
        <div class="caseDetail__ex__problem js-scroll">
          <div class="caseDetail__ex__problem__p">
            <p class="ttl">
              課題1
            </p>
            <p class="txt">
              検査場が明るすぎて眩しい・反射光が眩しくて目が疲れる
            </p>
          </div>
          <div class="caseDetail__ex__problem__a">
            <span class="ttl">施策1</span>
            <p class="txt">
              面発光の光源を導入する
            </p>
          </div>
          <div class="caseDetail__ex__problem__img">
            <div>
              <p class="caseDetail__ex__problem__imgTxt">
                眩しさを感じる光環境
              </p>
              <img src="/genbashiko/img/case/case04/image01.jpg" alt="" />
            </div>
            <div class="topEmp">
              <img src="/genbashiko/img/case/case04/image02.jpg" alt="" />
            </div>
            <div>
              <img src="/genbashiko/img/case/case04/image03.jpg" alt="" />
            </div>
            <div></div>
          </div>
          <p class="caseDetail__ex__problem__cap">
            ワークに当たる光が反射する際に、眼に強い反射光が入って眩しさを感じるケースは少なくありません。面発光で広範囲を照らす「ルーチ・リタープ」は、調光によって光源の強さを抑えながら明るさを確保できるので、光が分散され、全体として必要な明るさを保ちながら、眩しさを軽減することが可能です。作業員の目の疲労軽減や作業時間の短縮、不良の見落としを防ぐことにつながります。
          </p>
        </div>
        <p class="caseDetail__ex__download js-scroll">
          <a href="/genbashiko/upload/download_pdf/PA-3-1.pdf" target="_blank"
            >課題に関する資料ダウンロード</a
          >
        </p>
        <div class="caseDetail__ex__problem js-scroll">
          <div class="caseDetail__ex__problem__p">
            <p class="ttl">
              課題2
            </p>
            <p class="txt">
              反射光が眩しくて目が疲れる
            </p>
          </div>
          <div class="caseDetail__ex__problem__a">
            <p class="ttl">
              施策2
            </p>
            <p class="txt">
              面発光の光源を導入する
            </p>
          </div>
          <div class="caseDetail__ex__problem__img">
            <div>
              <p class="caseDetail__ex__problem__imgTxt">通常の照明</p>
              <img src="/genbashiko/img/case/case04/image04.jpg" alt="" />
            </div>
            <div>
              <p class="caseDetail__ex__problem__imgTxt">面発光</p>
              <img src="/genbashiko/img/case/case04/image05.jpg" alt="" />
            </div>
            <div>
              <img src="/genbashiko/img/case/case04/image06.jpg" alt="" />
            </div>
            <div>
              <img src="/genbashiko/img/case/case04/image07.jpg" alt="" />
            </div>
          </div>
          <p class="caseDetail__ex__problem__cap">
            ワークに当たる光が反射する際に、照射面に明暗差が生じやすかったり、眼に強い反射光が入って眩しさを感じるケースは少なくありません。面発光の「ルーチ・リタープ」は光源の強さを抑えながら明るさを確保できるので、眩しさを軽減することができます。
          </p>
        </div>
        <p class="caseDetail__ex__download js-scroll">
          <a href="/genbashiko/upload/download_pdf/PA-3-1.pdf" target="_blank"
            >課題に関する資料ダウンロード</a
          >
        </p>
      </section>
    </div>
  </div>
</template>
<script>
import PageTitle from "@/components/PageTitle.vue";

export default {
  components: {
    PageTitle,
  },
  head: {
  title: {
    inner: 'Case04'
  },
  meta: [
    { property: 'og:title', content: 'Case04｜GENBA SHIKO' },
    { name: 'description', content: '自動車エンジンなどの検査場の照明や反射光が眩しくて作業員の眼が疲れやすいなら、面発光の照明器具を導入して労働環境の改善をサポートします。' },
    { property: 'og:description', content: '自動車エンジンなどの検査場の照明や反射光が眩しくて作業員の眼が疲れやすいなら、面発光の照明器具を導入して労働環境の改善をサポートします。' },
    { property: 'og:url', content: 'https://luci.co.jp/genbashiko/case/case04' },
    { property: 'og:type', content: 'article' }
    ]
  }
};
</script>
<style lang="scss">
.caseDetail {
  &__ex {
    &__problem {
      &__img {
        margin-top: 0;
        > div {
          flex: 1 0 47%;
          margin-top: 30px;
          &:nth-of-type(1),
          &:nth-of-type(2) {
            margin-top: 20px;
          }
          &:nth-of-type(2n) {
            margin-left: 60px;
          }
          &.topEmp {
            &::before {
              content: "";
              display: block;
              margin-bottom: 20px;
              height: 20px;
            }
          }
        }
      }
      &__imgTxt {
        font-size: 1.6rem;
        margin-bottom: 20px;
        height: 20px;
      }
      &__cap {
        &.fslar {
          font-size: 1.6rem;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .caseDetail {
    &__ex {
      &__problem {
        &__img {
          margin: 0;
          div {
            margin-top: 20px;
            &:nth-of-type(1),
            &:nth-of-type(2) {
              margin-top: 25px;
            }
            &:nth-of-type(2n) {
              margin-left: 15px;
            }
            &.topEmp {
              &::before {
                content: "";
                display: block;
                margin-bottom: 10px;
                height: 15px;
              }
            }
          }
        }
        &__imgTxt {
          font-size: 1.2rem;
          margin-bottom: 10px;
          height: 15px;
        }
        &__cap {
          &.fslar {
            font-size: 1.2rem;
            margin: 25px 0 0;
          }
        }
      }
    }
  }
}
</style>
